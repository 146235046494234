import React, { Component } from 'react';


export default class Payment extends Component {

  constructor(props) {
    super(props)
  }
  
  render() {

    return (
      <div style={{textAlign: "center", fontSize: "30px", marginTop: "10%", width:"100%"}}>Apostas Encerradas</div>
    )
  }
      

}
